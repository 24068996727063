import React from 'react';

const IconLogo = () => (
  // <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
  //   <title>Logo</title>
  //   <g transform="translate(-8.000000, -2.000000)">
  //     <g transform="translate(11.000000, 5.000000)">
  //       <path
  //         d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
  //         fill="currentColor"
  //       />
  //       <polygon
  //         id="Shape"
  //         stroke="currentColor"
  //         strokeWidth="5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         points="39 0 0 22 0 67 39 90 78 68 78 23"
  //       />
  //     </g>
  //   </g>
  // </svg>
  // <svg
  //   id="svg"
  //   version="1.1"
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="400"
  //   height="400"
  //   viewBox="0, 0, 400,400">
  //   <g id="svgg">
  //     <path
  //       d="M40.936 37.000 C 41.922 39.750,67.559 115.350,97.906 205.000 L 153.084 368.000 172.418 368.000 L 191.752 368.000 134.958 200.000 L 78.164 32.000 58.654 32.000 C 44.757 32.000,39.660 33.438,40.936 37.000 M89.333 37.000 C 90.433 39.750,115.933 115.350,146.000 205.000 L 200.666 368.000 223.957 368.000 L 247.247 368.000 302.624 203.138 C 333.081 112.463,358.600 36.863,359.333 35.138 C 360.067 33.412,351.657 32.000,340.646 32.000 L 320.625 32.000 273.355 174.000 C 247.356 252.100,225.146 316.000,224.000 316.000 C 222.854 316.000,200.644 252.100,174.645 174.000 L 127.375 32.000 107.354 32.000 C 92.937 32.000,87.893 33.400,89.333 37.000 M239.524 131.517 L 206.441 231.034 213.912 255.708 C 218.021 269.279,222.715 281.206,224.343 282.212 C 225.972 283.219,246.218 227.333,269.334 158.021 L 311.364 32.000 291.986 32.000 L 272.607 32.000 239.524 131.517 "
  //       fill="currentColor"
  //     />
  //   </g>
  // </svg>
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
    <g id="svg">
      <path
        d="M5.189 1.483 C -0.524 5.249,0.010 -15.239,0.010 200.000 C 0.010 415.239,-0.524 394.751,5.189 398.517 C 8.691 400.826,391.309 400.826,394.811 398.517 C 400.524 394.751,399.990 415.239,399.990 200.000 C 399.990 -15.239,400.524 5.249,394.811 1.483 C 391.309 -0.826,8.691 -0.826,5.189 1.483 M376.563 200.000 L 376.563 376.563 200.000 376.563 L 23.438 376.563 23.438 200.000 L 23.438 23.438 200.000 23.438 L 376.563 23.438 376.563 200.000 M96.688 95.361 C 93.344 97.127,90.631 101.714,90.614 105.633 C 90.601 108.466,162.563 298.251,164.943 301.661 C 168.048 306.110,169.115 306.250,200.000 306.250 C 230.885 306.250,231.950 306.111,235.058 301.661 C 237.438 298.253,309.400 108.468,309.387 105.633 C 309.368 101.592,306.657 97.115,303.146 95.323 C 298.578 92.993,245.730 92.884,241.419 95.196 C 237.170 97.476,236.991 97.875,218.279 147.070 C 208.513 172.744,200.285 193.750,199.994 193.750 C 199.702 193.750,191.411 172.592,181.568 146.733 C 162.878 97.629,162.628 97.074,158.302 95.021 C 153.838 92.903,100.790 93.196,96.688 95.361 M166.841 176.553 C 192.314 243.344,191.322 241.404,200.000 241.404 C 208.687 241.404,207.731 243.273,233.183 176.529 L 255.366 118.359 267.515 118.144 L 279.664 117.929 278.975 119.707 C 278.596 120.684,264.742 156.992,248.188 200.391 C 231.633 243.789,217.848 279.912,217.555 280.664 C 217.069 281.908,215.485 282.031,200.000 282.031 C 184.516 282.031,182.931 281.908,182.442 280.664 C 182.147 279.912,168.710 244.668,152.582 202.344 C 136.454 160.020,122.597 123.712,121.789 121.660 L 120.320 117.929 132.484 118.144 L 144.648 118.359 166.841 176.553 "
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLogo;
