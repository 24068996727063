import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
    <title>Loader Logo</title>
    <g>
      <path
        d="M5.189 1.483 C -0.524 5.249,0.010 -15.239,0.010 200.000 C 0.010 415.239,-0.524 394.751,5.189 398.517 C 8.691 400.826,391.309 400.826,394.811 398.517 C 400.524 394.751,399.990 415.239,399.990 200.000 C 399.990 -15.239,400.524 5.249,394.811 1.483 C 391.309 -0.826,8.691 -0.826,5.189 1.483 M376.563 200.000 L 376.563 376.563 200.000 376.563 L 23.438 376.563 23.438 200.000 L 23.438 23.438 200.000 23.438 L 376.563 23.438 376.563 200.000 M96.688 95.361 C 93.344 97.127,90.631 101.714,90.614 105.633 C 90.601 108.466,162.563 298.251,164.943 301.661 C 168.048 306.110,169.115 306.250,200.000 306.250 C 230.885 306.250,231.950 306.111,235.058 301.661 C 237.438 298.253,309.400 108.468,309.387 105.633 C 309.368 101.592,306.657 97.115,303.146 95.323 C 298.578 92.993,245.730 92.884,241.419 95.196 C 237.170 97.476,236.991 97.875,218.279 147.070 C 208.513 172.744,200.285 193.750,199.994 193.750 C 199.702 193.750,191.411 172.592,181.568 146.733 C 162.878 97.629,162.628 97.074,158.302 95.021 C 153.838 92.903,100.790 93.196,96.688 95.361 M166.841 176.553 C 192.314 243.344,191.322 241.404,200.000 241.404 C 208.687 241.404,207.731 243.273,233.183 176.529 L 255.366 118.359 267.515 118.144 L 279.664 117.929 278.975 119.707 C 278.596 120.684,264.742 156.992,248.188 200.391 C 231.633 243.789,217.848 279.912,217.555 280.664 C 217.069 281.908,215.485 282.031,200.000 282.031 C 184.516 282.031,182.931 281.908,182.442 280.664 C 182.147 279.912,168.710 244.668,152.582 202.344 C 136.454 160.020,122.597 123.712,121.789 121.660 L 120.320 117.929 132.484 118.144 L 144.648 118.359 166.841 176.553 "
        fill="currentColor"
      />
      {/* <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      /> */}
    </g>
  </svg>
);

export default IconLoader;
